<template>
  <div
    class="post-card card card-hover flex flex-col justify-between relative"
    style="max-height: 38rem;"
  >
    <div class="flex flex-col gap-4">
      <div class="flex flex-row">
        <div>
          <div class="" v-if="thumbnail"> <!-- aspect-w-1 aspect-h-1 -->
            <img
              class="rounded-tl-lg rounded-tr-lg object-cover w-screen"
              :src="thumbnail"
              :alt="`post-${post.id}`"
              @error="addDefaultSrc"
              :class="showOptionsMenu ? 'rounded-br-lg' : ''"
            />
          </div>
          <div
            v-else
            class="relative flex flex-col rounded-lg h-full items-center justify-center w-full p-16 gap-4 bg-gradient-to-bl from-green-400 to-blue-500"
          >
            <img
              :src="alternativeThumbnail"
              class="h-full w-full bg-transparent"
              alt=""
              @error="addDefaultSrc"
            />
            <span
              class="absolute bottom-4 text-xl font-semibold text-white whitespace-nowrap"
            >
              Sem imagem
            </span>
          </div>
        </div>
        <div
          v-if="showOptionsMenu"
          class="flex flex-col items-center pt-4 px-2 gap-2"
        >
          <UserTagsModal
            :postId="post.uid"
          >
            <button
              class="rounded-full h-8 w-8 hover:bg-gray-200"
              :title="$t('page.tags.action.open_modal')"
            >
              <i class="fas fa-tags"></i>
            </button>
          </UserTagsModal>
          <PostPreview
            :post="post"
          >
            <button
              class="rounded-full h-8 w-8 hover:bg-gray-200"
              :title="$t('action.preview_post')"
            >
              <i class="fas fa-mobile-alt"></i>
            </button>
          </PostPreview>
          <button
            class="rounded-full h-10 w-10 bg-blue-700 hover:bg-blue-500"
            :title="$t('action.edit')"
            @click.prevent="edit"
          >
            <i class="far fa-edit text-white"></i>
          </button>
          <PostDeleteButton
            class="rounded-full h-8 w-8 hover:bg-red-200 mt-auto"
            :postId="post.uid"
          >
            <i class="far fa-trash-alt text-red-500"></i>
          </PostDeleteButton>
        </div>
      </div>
      <div class="px-4" v-if="tags.length" >
        <post-tags :tags="tags" />
      </div>
      <div v-if="!lock && post.title" v-html="post.title" class="text-sm font-semibold px-4 mb-2"></div>
    </div>
    <div
      class="card-body p-4 whitespace-pre-wrap select-none overflow-y-auto"
      :class="{ outshine: lock }"
    >
      <div v-if="lock">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Omnis quidem itaque beatae, rem tenetur quia iure, eum natus enim.
      </div>
      <div v-else v-html="content" class="text-sm"></div>
      <div class="mt-4" v-html="hashtags"></div>
    </div>
    <div class="card-footer">
      <div v-if="approving" class="flex justify-center items-center space-x-2">
        <i class="fas fa-spinner fa-spin"></i>
        <div>{{ $t('sentence.wait') }}</div>
      </div>
      <!-- <div v-else-if="editable" class="flex justify-end items-center">
        <button
          @click.prevent="edit"
          class="btn btn-primary"
        >
          <i class="far fa-edit"></i>
          {{ $t('action.edit') }}
        </button>
      </div> -->
      <template v-else>
        <div v-if="isApproved" class="py-2 px-12 bg-green-500 text-white text-sm text-center shadow rounded-full mx-auto w-max">
          <span class="font-bold font-sora text-white">{{ $t('page.review.sentence.approved_content') }}</span>
          <span class="fonst font-semibold text-xs text-white block">{{ $t('page.review.sentence.ready_to_edit') }}</span>
        </div>
        <div v-else-if="isRejected" class="py-2 px-12 bg-red-500 text-white text-sm text-center shadow rounded-full mx-auto w-max">
          <span class="font-bold font-sora text-white">{{ $t('page.review.sentence.content_rejected') }}</span>
          <span class="fonst font-semibold text-xs text-white block">{{ $t('page.review.sentence.do_not_show_again') }}</span>
        </div>
        <div v-else class="flex justify-end items-center space-x-4">
          <reject-modal
            v-if="rejectable"
            @reject="reject"
          />
          <button
            class="btn btn-primary"
            v-if="approvable"
            @click.prevent="approve"
          >
            <i class="far fa-thumbs-up"></i>
            {{ $t('action.to_approve') }}
          </button>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { useApprove, useReject } from '@/api/publications/usePosts'
import { computed, defineComponent, toRefs, unref } from 'vue'
import { useRouter } from 'vue-router'
import PostTags from './post/PostTags.vue'
import RejectModal from './RejectModal.vue'
import PostPreview from '@/components/posts/post/PostPreview.vue'
import UserTagsModal from '../tags/UserTagsModal.vue'
import PostDeleteButton from '@/components/posts/post/PostDeleteButton.vue'
import { noCache } from '@/composables/utils/noCache'

export default defineComponent({
  components: { RejectModal, PostTags, PostPreview, UserTagsModal, PostDeleteButton },
  props: {
    post: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    },
    rejectable: {
      type: Boolean,
      default: false
    },
    approvable: {
      type: Boolean,
      default: true
    },
    lock: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const router = useRouter()

    const { post } = toRefs(props)

    const thumbnail = computed(() => {
      let output = null
      const { creative, thumbnail, thumbnails } = unref(post)

      if (creative?.thumbnail) {
        output = creative.thumbnail
      } else if (thumbnails && thumbnails['small']) {
        output = thumbnails['small']
      } else if (thumbnail) {
        output = thumbnail
      }
      return output ? noCache(output) : null
    })

    const alternativeThumbnail = computed(() => {
      return require("@/assets/img/no-image.png")
    })

    const hasThumbnail = computed(() => unref(thumbnail) !== null)

    const postId = unref(post).uid

    const useVistaCreate = 'creative' in unref(post) || 'vistaCreateTemplate' in unref(post)

    const {
      isApproved,
      isLoading: approving,
      approve
    } = useApprove(postId, useVistaCreate)

    const {
      isRejected,
      isLoading: rejecting,
      reject
    } = useReject(postId)

    const tags = computed(() => {
      return unref(post).tags || []
    })

    const hashtags = computed(() => {
      let result = unref(post).hashtags && unref(post).hashtags.length
        ? unref(post).hashtags.join(' ')
        : ''

      if (unref(post).emphasis) {
        unref(post).emphasis.map((emphasi: string) => {
          result = result.replace(emphasi, `<span class="bg-teal-200 font-semibold px-1">${emphasi}</span>`)
        })
      }

      return result
    })

    const content = computed(() => {
      let result = unref(post).content
        ? unref(post).content.trim()
        : ''

      if (unref(post).emphasis) {
        unref(post).emphasis.map((emphasi: string) => {
          result = result.replace(emphasi, `<span class="bg-teal-200 font-semibold px-1">${emphasi}</span>`)
        })
      }

      return result
    })

    const edit = () => {
      router.push({ name: 'edit-post', params: { id: postId }})
    }

    const addDefaultSrc = (e: Event) => {
      const target = e.target as HTMLImageElement
      if (target) {
        target.src = require('@/assets/img/no-image.png')
      }
    }

    const showOptionsMenu = computed(() => {
      return props.editable
    })

    return {
      approve,
      reject,
      edit,
      addDefaultSrc,
      showOptionsMenu,
      hasThumbnail,
      isApproved,
      isRejected,
      approving,
      rejecting,
      thumbnail,
      alternativeThumbnail,
      tags,
      hashtags,
      content
    }
  }
})
</script>

<style scoped>
.card {
  flex: 1 0 0%;
}

.outshine div {
  filter: blur(2.5px);
}
</style>
